import { CgProfile } from "react-icons/cg";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Signup.css";
import $ from "jquery";
import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function Signup() {
  const [inputs, setInputs] = useState({});
  const [submitMsg, setSubmitMsg] = useState();
  const [result, setResult] = useState({});
  const [country, setCountry] = useState({});
  const options = useMemo(() => countryList().getData(), []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: [value] }))
  }
  const handleCountry = (e) => {
    const name = "country";
    const value = e;
    setInputs(values => ({ ...values, 'country': [e.label] }))
    console.log(e);
    setCountry(value);
  }

  const handleSubmit = async (e) => {   
    e.preventDefault();
    setSubmitMsg("Signup successfull");
    // console.log(inputs);
    // var json = JSON.parse(JSON.stringify(inputs)); 
    // console.log(json);
    // console.log(inputs);
    // alert("signup successful");
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
        //  console.log(data);
      },
    });

    // const allInputValues={country:inputs.country,email:inputs.email,name:inputs.name,password:inputs.password};
    // let res=await fetch("http://localhost" ,{
    //   method:"POST",
    //   headers:{'content-type':'application/json'},
    //   body:JSON.stringify(allInputValues);
    // }
    // );

  }

  return (
    <div className='signup'>
      <h1 className='signup-icon'>
        <CgProfile />
      </h1>
      <h1 className='signup-icon'>
        Sign Up
      </h1>
      <form
        action="/insert.php"
        method="post"
        onSubmit={(event) => handleSubmit(event)}
        className='form' >
        <input className='signup-input' placeholder=" Name" type="text" name="name" value={inputs.name}
          onChange={handleChange}
        />

        <Select className='signup-country' options={options} placeholder="country" name="country" value={country}
          onChange={handleCountry} />
        {/* <CountryDropdown className='signup-input'  name="country" value={CountryDropdown.value}
          onChange={handleChange} /> */}

        <input className='signup-input' placeholder=" email@example.com" type="email" name="email" value={inputs.email}
          onChange={handleChange}
        />
        <input className='signup-input' placeholder=" Password" type="password" name="password" value={inputs.password}
          onChange={handleChange}
        />
        <input className='submit-button' type="submit" value="Sign Up" />
      </form>
      <p className="submit-message"> {submitMsg} </p>
    </div>
  )
}
export default Signup;