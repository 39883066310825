import React, { useState , useRef, useEffect} from 'react'
import { FaHome } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { FaHamburger } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";

const Navbar = () => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActive(false);
    }
  };
  const [isActive, setActive] = useState(false);
  const openHam = () => {
    const toggleClass = () => {
      setActive(!isActive);
    };
    toggleClass();
  };
  return (
    <>
      <nav className="navbar" ref={wrapperRef}>
        <div className="navbar-container">
          <ul className={`${isActive ? "hamburger-active" : "nav-list"}`}>
            <li className=" hamburger">
              <NavLink to="/" className="nav-link"  onClick={openHam}>
                <FaHamburger />
              </NavLink>
            </li>
              <li className={`${isActive ? "" : "nav-item"}`}>
              <NavLink to="/" className="nav-link" >
                {FaHome}
              </NavLink>
            </li>
            <li className={`${isActive ? "" : "nav-item"}`}>
              <NavLink to="/blog" className="nav-link" >
                Blogs
              </NavLink>
            </li>
            <li className={`${isActive ? "" : "nav-item"}`}>
              <NavLink to="/forum" className="nav-link" >
                Forum
              </NavLink>
            </li>
            <li >
              <NavLink to="/login" className="nav-link" >
                Log In
              </NavLink>
            </li>
            <li className={`${isActive ? "" : "nav-item"}`}>
              <NavLink to="/Signup" className="nav-link" >
                Sign Up
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
