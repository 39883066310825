import React, { useState } from 'react'
import { Link } from "react-router-dom";

import "./Sidebar.css";

const Sidebar = () => {
    let data = require('../assets/data.json');
    return (
        <>

            <div className='Sidebar-menu'>
                <ul className='Sidebar-list'>
                    <li className='Sidebar-items'>
                        <Link
                            className='Sidebar-link' to="/ContinentList" state={{ prop: data.asia, continentName: "Asia" }}>
                            Asia
                        </Link>
                    </li>
                    <li className='Sidebar-items'>
                        <Link className='Sidebar-link' to="/ContinentList" state={{ prop: data.europe, continentName: "Europe" }} >
                            Europe
                        </Link>
                    </li>
                    <li className='Sidebar-items'>
                        <Link className='Sidebar-link' to="/ContinentList" state={{ prop: data.middleEast, continentName: "Middle East" }}>
                            Middle East
                        </Link>
                    </li>
                    <li className='Sidebar-items'>
                        <Link className='Sidebar-link' to="/ContinentList" state={{ prop: data.oceania, continentName: "Oceania" }} >
                            Oceania
                        </Link>
                    </li>
                    <li className='Sidebar-items'>
                        <Link className='Sidebar-link' to="/ContinentList" state={{ prop: data.northAmerica, continentName: "Noth America" }} >
                            North America
                        </Link>
                    </li>
                    <li className='Sidebar-items'>
                        <Link className='Sidebar-link' to="/ContinentList" state={{ prop: data.southAmerica, continentName: "South America" }} >
                            South America
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Sidebar
