import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";

const Footer = () => {
    // const TwitterIcon = <SocialIcon url="https://twitter.com" />
    // const InstagramIcon = <SocialIcon url="https://instagram.com" />
    // const FacebookIcon = <SocialIcon url="https://facebook.com" />
    // const Whatsapp-icon = <SocialIcon url="https://whatsapp.com" />

    let copyright = String.fromCodePoint(0x00A9);
    return (
        <footer className='footer'>
            <div className="footer-text">
                {copyright} 2023 tripmadeasy.com All rights reserved.
            </div>
            <div className='social-media' >
                <IconContext.Provider value={{ color: "white", size: "3%" }}>
                    {/* <div> */}
                    <FaFacebook />
                    <FaTwitter />
                    <FaInstagramSquare />
                    {/* </div> */}
                </IconContext.Provider>
            </div>
        </footer>
    )
}

export default Footer