import React, { useState } from 'react'
// import { Link } from "react-router-dom";

import "./Forum.css";
// const [inputs, setInputs] = useState({});
function Forum() {
  return (
    <div className='forum'>
      {/* <form
        // action="/insert_qa.php"
        // method="post"
        onSubmit={(event) => handleSubmit(event)}
        className='form' >
        <input className='signup-question' placeholder="ask your travel query" type="text" name="question" value={inputs.question}
          onChange={handleChange}
        />
         <input className='submit' type="submit" value="Sign Up" />
      </form> */}
    </div>
  );
};

export default Forum;