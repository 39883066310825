import React from 'react';
import { Link } from "react-router-dom";
import './Continent.css';

function Continent(props) {
    const myLists = props.CountryList;
    const listItems = myLists.map((myList) =>
        <div className="country-card"
            key={myList.country}>
            <Link 
            // className="country-card"
                to="/Country" state={{ prop: myList.country }}>
                <img  className='image-flag'
                    src={require("..//assets/images/" + myList.flag_url)} />
                <div>
                    <strong>{myList.country} </strong>
                </div>
            </Link>
        </div>
    );
    
    return (
        <div className="continent" >
                {listItems}
            
        </div>
    );
}
export default Continent;