import { useState } from 'react';
import { CgProfile } from "react-icons/cg";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Login.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";

function Login() {
  const [result, setResult] = useState({});
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: [value] }))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    alert("login successful");
    const form = $(event.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
        //  console.log(data);
      },
    });
  }

  return (
    <div className='login'>
      <h1 className='login-icon'>
        <CgProfile />
      </h1>
      <h1 className='login-icon'>
        Welcome
      </h1>
      <form className='form'
        action="/login.php"
        method="post"
        onSubmit={(event) => handleSubmit(event)}
        onSubmit={handleSubmit}>
        <input className='login-input'
          placeholder=" Email"
          type="email"
          name="email"
          value={inputs.value}
          onChange={handleChange}
        />
        <input className='login-input'
          placeholder=" Password"
          type="password"
          name="password"
          value={inputs.value}
          onChange={handleChange}
        />
        <input id='submit-button'
          type="submit"
          value="Login" />
      </form>
      <NavLink to="/signup">
        New User? Sign Up
      </NavLink>
    </div>
  )
}
export default Login;