import React from 'react';
import { useLocation } from "react-router-dom"
import Continent from './Continent'
import "./ContinentList.css";

function ContinentList(props) {
        const location = useLocation()
        const xyz = location.state;
        const CountryList = xyz.prop;
        const continentName = xyz.continentName;
        return (
                <div className='continentList'>
                        <h1 className='continent-name'>
                                {continentName}
                        </h1>
                        <Continent CountryList={CountryList} />
                </div>
        );
}
export default ContinentList;