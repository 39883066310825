import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import bus_hills from "../images/bus_hills.jpeg";
// import wander1 from "../images/wander1.jpg";
// import retro from "../images/retro.jpeg";
// import balloon_sunset from "../images/balloon_sunset.jpeg";
import maldives from "../assets/images/Maldives.png";
import greece from "../assets/images/Greece.png";
import weekend from "../assets/images/weekend.png";
// import beach_boat from "../assets/images/beach_boat_sunset.webp";
import "./Homepage.css";

function Homepage() {
    const bio1 = "Plan a trip with us";
    const bio2 = "Book hotels, Flights and exciting activities to make your trip memorable";

    return (

        <div className='home'>
            <h1 className='bio-home'> {bio1}</h1>
            <Carousel className="Carousel-home"
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                showStatus={false}
                showIndicators={false}
                dynamicHeight={true}>
                <div>
                    <img src={maldives} />

                </div>
                <div>
                    <img src={greece} />

                </div>
                <div>
                    <img src={weekend} />

                </div>
            </Carousel>
        </div>




    )
}

export default Homepage;
