import React from 'react';
import './Country.css';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"

function Country(props) {
  const location = useLocation()
  const xyz = location.state;
  const countryName = xyz.prop;
  const [countryData, setCountryData] = useState([]);
  // fetch('https://country.tripmadeasy.com/country_json.php')
  // http://ip.jsontest.com/
  useEffect(()=>{
    // try{
      fetch("https://country.tripmadeasy.com/country_json.php")
    .then((res)=>{
      return res.json();
    })
    .then((data)=>{
      console.log(data);
    });
    // }
  //   catch(error){
  // console.log(error);
  //   };
  },[]);

  // useEffect(() => {
  //   const getCountryData = async () => {
  //     const res = await fetch('http://ip.jsontest.com/');
  //     const resData= await res.json;
  //     // const countryData = await res.json;
  //     // setCountryData(countryData);
  //     console.log(countryData);
  //   }
  //   getCountryData();
  // }, []);

  return (
    <div className='countryName'>
      <h1>  {countryName}</h1>
      <img className='country-img' src={require("..//assets/images/country_images/" + countryName + ".jpeg")} />
      <h1> Important info</h1>
      <ul>
        <li>
          {/* Visa : https://www.visa.gov.bd/ */}
        </li>
        <li>
          {/* Telephone operators: grameenphone, Robi Axiata Limited, Banglalink, teletalk Bangladesh limited */}
        </li>
      </ul>
    </div>
  );
}
export default Country;