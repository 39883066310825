import React from 'react';
import './App.css';

import Forum from './components/Forum';
import Navbar from './components/Navbar';

import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import Signup from './components/Signup';
import Homepage from './components/Homepage';

import ContinentList from './components/ContinentList';
import Continent from './components/Continent';
import Country from './components/Country';
import { Routes, Route, Link } from "react-router-dom";


function App() {
  return (
      <div className="App">
        {/* <Header/> */}
        <Navbar/>
        <Sidebar/>
    
        <Routes> 
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/Forum" element={<Forum />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/Signup" element={<Signup />}></Route>
          <Route path="/ContinentList" element={<ContinentList />}></Route>
          <Route path="/Country" element={<Country />}></Route>
        </Routes>
        <Footer/>
      </div>
  );
}

export default App;
